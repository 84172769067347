import React, { useState } from "react";
import {Link, useParams, useSearchParams} from "react-router-dom";
import "./ResultsPage.css";
import Loading from "../../common/Loading";
import Pagination from "../../common/Pagination";
import FileSummary from "./FileSummary";
import API from "../../common/API";

export default function ResultsPage() {
    // const [user] = useOutletContext();
    const { fileId } = useParams();
    const [init, setInit] = useState(false);
    const [loading, setLoading] = useState(true);
    const [file, setFile] = useState(null);
    const [fileReturnCodes, setFileReturnCodes] = useState([]);
    const [results, setResults] = useState([]);
    const [queryParams, setQueryParams] = useState({});
    const [searchParams, setSearchParams] = useSearchParams();

    const getFileReturnCodes = () => {
        setLoading(true);
        API.get(`member-files/${fileId}/return-codes`)
            .then((response) => {
                setFileReturnCodes(response);
                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
            });
    }

    const getFileName = () => {
        return `${file.file_name.split(".").slice(0, -1).join(".")}_${file.created_date.substring(0, 10)}_${file.id}.csv`;
    }

    const getResults = (fileId, params) => {
        setLoading(true);
        params = (params) ? {...queryParams, ...params} : queryParams;
        setQueryParams(params);
        setSearchParams(new URLSearchParams(params));
        API.get(`member-files/${fileId}/results`, params)
            .then((response) => {
                setResults(response);
                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
            });
    }

    const refreshResults = () => {
        const params = {...queryParams, ...{page: 1}};
        getResults(fileId, params);
    }

    const getReturnCodeById = (id) => {
        return fileReturnCodes.find((e) => e.id === id) || {};
    }

    const onValueChange = (event) => {
        getResults(fileId, {[event.target.name]: event.target.value, page: 1});
    }

    const onPageChange = (value) => {
        getResults(fileId, {page: value})
    }

    const getBackParams = () => {
        const params = localStorage.getItem('messagesParams');
        return params ? "?" + new URLSearchParams(JSON.parse(params)).toString() : "";
    }

    if (fileId && !init) {
        const params = Object.fromEntries(searchParams);
        setQueryParams(params);
        API.get(`member-files/${fileId}`)
            .then((response) => {
                setFile(response);
                getResults(fileId, params);
                getFileReturnCodes();
                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
            });
        if (Object.keys(params).length) {
            getResults(fileId, params);
        }
        setInit(true);
    }

    return (
    <React.Fragment>
      {loading && <Loading/>}
      <div className="content-header">
        <h1 className="h2 content-header-title">Data profiler &mdash; Records with errors</h1>
      </div>
      <div hidden={!loading && !!file}>
          Could not find the file by ID
      </div>
      {!!file && <div className={"container"}>
          <FileSummary file={file} />
          <div className="row">
              <div className="col-6 text-right">
                  <Link
                      to={`/dashboard/data-profiler/${fileId}/messages${getBackParams()}`}
                      className="btn btn-secondary mt-5">
                        Back to MF file search
                  </Link>
              </div>
              <div className="col-6 text-left">
                  <button
                      onClick={() => API.download(`member-files/${fileId}/results/csv`, getFileName())}
                      className="btn btn-primary mt-5">
                      Full result Download
                  </button>
              </div>
          </div>
          {!!fileReturnCodes && <>
              <hr className="mt-5 mb-5" />
              <h3>Warnings/Errors found</h3>
              <div className="row">
                  <div className="col-6">
                      <select size="5" className="w-100" name="return_code" onChange={onValueChange} value={queryParams.return_code}>
                          {fileReturnCodes.map((code) => (
                              <option key={code.id} value={code.id}>
                                  {code.id} - {code.category} - {code.description}
                              </option>
                          ))}
                      </select>
                  </div>
                  <div className="col-6">
                      <button className="btn btn-primary" onClick={refreshResults}>Refresh list</button>
                  </div>
              </div>
          </>}
          {!fileReturnCodes && <>
              <hr className="mt-5 mb-5" />
              <h3>Warnings/Errors not found</h3>
          </>}
          {!!queryParams.return_code && <>
              <hr className="mt-5 mb-5"/>
              <h3>Current message selection</h3>
              <div className="table table-selection" role="table">
                  <div className="table-header" role="rowgroup">
                      <div className="table-row" role="row">
                          <div className="table-col table-selection-id" role="columnheader">
                              <span className="table-col-header"><span>ID</span></span>
                          </div>
                          <div className="table-col table-selection-description" role="columnheader">
                              <span className="table-col-header"><span>Description</span></span>
                          </div>
                          <div className="table-col table-selection-type" role="columnheader">
                              <span className="table-col-header"><span>Type</span></span>
                          </div>
                      </div>
                  </div>
                  <div className="table-body" role="rowgroup">
                      <div className="table-row" role="row">
                          <div className="table-col table-selection-id" data-title="ID" role="cell">
                              {queryParams.return_code}
                          </div>
                          <div className="table-col table-selection-description" data-title="Description" role="cell">
                              {getReturnCodeById(queryParams.return_code).description}
                          </div>
                          <div className="table-col table-selection-type" data-title="Type" role="cell">
                              {getReturnCodeById(queryParams.return_code).category}
                          </div>
                      </div>
                  </div>
              </div>
          </>}
          <hr className="mt-5 mb-5" />
          <div className="row">
              <div className="col-12">
                  <div className="table table-results" role="table">
                      <div className="table-header" role="rowgroup">
                          <div className="table-row" role="row">
                              <div className="table-col table-results-row-number" role="columnheader">
                                  <span className="table-col-header"><span>Row #</span></span>
                              </div>
                              <div className="table-col table-results-entity-id" role="columnheader">
                                  <span className="table-col-header"><span>Entity Id</span></span>
                              </div>
                              <div className="table-col table-results-field-name" role="columnheader">
                                  <span className="table-col-header"><span>Field name</span></span>
                              </div>
                              <div className="table-col table-results-field-content" role="columnheader">
                                  <span className="table-col-header"><span>Field content</span></span>
                              </div>
                          </div>
                      </div>
                      <div className="table-body" role="rowgroup">
                          {!!results.data && !!results.data.length && results.data.map((d) => (
                              <div key={d.ProfilerResult.id} className="table-row" role="row">
                                  <div className="table-col table-results-row-number" data-title="Row #" role="cell">
                                      {d.ProfilerResult.file_row_number}
                                  </div>
                                  <div className="table-col table-results-entity-id" data-title="Entity Id" role="cell">
                                      {d.entity_id}
                                  </div>
                                  <div className="table-col table-results-field-name" data-title="Field name" role="cell">
                                      <span>{d.ProfilerResult.field_name}</span>
                                  </div>
                                  <div className="table-col table-results-field-content" data-title="Field content" role="cell">
                                      <span>{d.ProfilerResult.current_value}</span>
                                  </div>
                              </div>))
                          }
                      </div>
                  </div>
                  {!!results.data && <Pagination {...results} onPageChange={onPageChange} />}
              </div>
          </div>
          <hr className="mt-5 mb-5" />
      </div>}
    </React.Fragment>
    );
}
