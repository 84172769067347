import React from "react";

export default function HomePage() {
  return (
    <React.Fragment>
      <div className="content-header">
        <h1 className="h2 content-header-title">Home</h1>
      </div>

      <div className="row"></div>
    </React.Fragment>
  );
}
