import "./App.css";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import LoginPage from "./components/login/LoginPage";
import Dashboard from "./components/Dashboard";
import HomePage from "./components/dashboard/home/HomePage";
import ImportFilesPage from "./components/dashboard/import-files/ImportFilesPage";
import ImportHistoryPage from "./components/dashboard/import-history/ImportHistoryPage";
import ReportsPage from "./components/dashboard/reports/ReportsPage";
import MessagesPage from "./components/dashboard/data-profiler/MessagesPage";
import ResultsPage from "./components/dashboard/data-profiler/ResultsPage";
import ResultSummary from "./components/dashboard/data-profiler/ResultSummary";
import ResultFiles from "./components/dashboard/data-profiler/ResultFiles";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<Navigate to="/login" />}></Route>
          <Route path="login" element={<LoginPage />} />
          <Route path="/dashboard" element={<Dashboard />}>
            <Route index element={<HomePage />} />
            <Route path="import-files" element={<ImportFilesPage />} />
            <Route path="import-history" element={<ImportHistoryPage />} />
            <Route path="data-profiler">
              <Route path=":fileId/messages" element={<MessagesPage />} />
              <Route path=":fileId/results" element={<ResultsPage />} />
              <Route path="result-summary" element={<ResultSummary />} />
              <Route path="result-files" element={<ResultFiles />} />
            </Route>
            <Route path="reports" element={<ReportsPage />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
