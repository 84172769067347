import React from "react";
import "./FileSummary.css";

export default function FileSummary(props) {

    const fileTypeIds = [
        { key: "1", value: "Entity" },
        { key: "2", value: "Client Engagement" },
        { key: "3", value: "Business Relationship" },
        { key: "4", value: "Personnel Relationship" },
    ];

    const getTypeName = (k) => {
        const tn = fileTypeIds.find((r) => r.key === String(k));
        return (tn.value) ? tn.value : null;
    }

    return (
        <>
            <div className="table table-summary" role="table">
                <div className="table-header" role="rowgroup">
                    <div className="table-row" role="row">
                        <div className="table-col table-summary-member-firm" role="columnheader">
                            <span className="table-col-header"><span>Member firm</span></span>
                        </div>
                        <div className="table-col table-summary-file-type" role="columnheader">
                            <span className="table-col-header"><span>File type</span></span>
                        </div>
                        <div className="table-col table-summary-file-name" role="columnheader">
                            <span className="table-col-header"><span>File name</span></span>
                        </div>
                        <div className="table-col table-summary-file-id" role="columnheader">
                            <span className="table-col-header"><span>File id</span></span>
                        </div>
                        <div className="table-col table-summary-rcvd-date" role="columnheader">
                            <span className="table-col-header"><span>Rcvd date</span></span>
                        </div>
                        <div className="table-col table-summary-run-date" role="columnheader">
                            <span className="table-col-header"><span>Run date</span></span>
                        </div>
                        <div className="table-col table-summary-total" role="columnheader">
                            <span className="table-col-header"><span>Total</span></span>
                        </div>
                        <div className="table-col table-summary-pass" role="columnheader">
                            <span className="table-col-header"><span>Pass</span></span>
                        </div>
                        <div className="table-col table-summary-fail" role="columnheader">
                            <span className="table-col-header"><span>Fail</span></span>
                        </div>
                    </div>
                </div>
                <div className="table-body" role="rowgroup">
                    <div className="table-row table-row-hoverable" role="row">
                        <div className="table-col table-summary-member-firm" data-title="Member firm" role="cell">
                            <span>{props.file.member_firm_id}</span>
                        </div>
                        <div className="table-col table-summary-file-type" data-title="File type" role="cell">
                            <span>
                                {getTypeName(props.file.file_type_id)}
                            </span>
                        </div>
                        <div className="table-col table-summary-file-name" data-title="File name" role="cell">
                            <span>{props.file.file_name}</span>
                        </div>
                        <div className="table-col table-summary-file-id" data-title="File id" role="cell">
                            <span>{props.file.id}</span>
                        </div>
                        <div className="table-col table-summary-rcvd-date" data-title="Rcvd date" role="cell">
                            <span>{props.file.received_date.substring(0, 10)}</span>
                        </div>
                        <div className="table-col table-summary-run-date" data-title="Run date" role="cell">
                            <span>{props.file.created_date.substring(0, 10)}</span>
                        </div>
                        <div className="table-col table-summary-total" data-title="Total" role="cell">
                            <span>{props.file.total}</span>
                        </div>
                        <div className="table-col table-summary-pass" data-title="Pass" role="cell">
                            <span>{props.file.passed}</span>
                        </div>
                        <div className="table-col table-summary-fail" data-title="Fail" role="cell">
                            <span>{props.file.failed}</span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}