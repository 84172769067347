import React, {useState} from "react";
import Loading from "../../common/Loading";
import DatePickerField from "../../common/DatePickerField";
import "./ResultSummary.css";
import {Link, useSearchParams} from "react-router-dom";
import API from "../../common/API";

export default function ResultSummary() {
    const initialValues = {
        member_firm: "",
        received_from: "",
        date_from: "",
        date_to: "",
        file_type: undefined,
    };

    const fileTypeIds = [
        {key: "", value: "All"},
        {key: "1", value: "Entity"},
        {key: "2", value: "Client Engagement"},
        {key: "3", value: "Business Relationship"},
        {key: "4", value: "Personnel Relationship"},
    ];

    const [init, setInit] = useState(false);
    const [memberFirms, setMemberFirms] = useState([]);
    const [receivedFrom, setReceivedFrom] = useState([]);
    const [loading, setLoading] = useState(true);
    const [results, setResults] = useState(false);
    const [queryParams, setQueryParams] = useState(initialValues);
    const [searchParams, setSearchParams] = useSearchParams();

    const getSummary = (params) => {
        setLoading(true);
        params = (params) ? {...queryParams, ...params} : queryParams;
        params = Object.fromEntries(Object.entries(params).filter(([_, v]) => ![null, undefined, ""].includes(v)));
        localStorage.setItem('resultSummaryParams', JSON.stringify(params));
        setQueryParams(params);
        setSearchParams(new URLSearchParams(params));
        API.get(`member-files/summary`, params)
            .then((response) => {
                setResults(response);
                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
            });
    }

    const onValueChange = (event) => {
        getSummary({[event.target.name]: event.target.value, page: 1});
    }

    const resetQueryParams = () => {
        setQueryParams(initialValues);
        setResults(false);
    }

    const urlToResults = (params) => ({
        pathname: '/dashboard/data-profiler/result-files',
        search: new URLSearchParams({...queryParams, ...params}).toString()
    })

    if (!init) {
        const params = Object.fromEntries(searchParams);
        setQueryParams(params);
        API.get('user/account');
        API.get('member-firm')
            .then((response) => {
                setMemberFirms(response);
                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
            });
        setLoading(true);
        API.get('member-files/received-from')
            .then((response) => {
                setReceivedFrom(response);
                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
            });
        if (Object.keys(params).length) {
            getSummary(params);
        } else {
            getSummary();
        }
        setInit(true);
    }

    return (
        <React.Fragment>
            {loading && <Loading/>}
            <div className="content-header">
                <h1 className="h2 content-header-title">Data profiler &mdash; Member firm result summary</h1>
            </div>
            <div className="container">
                <div className="row">
                    <div className="form-group col-md-4">
                        <label htmlFor="memberFirm" className="form-label">Member Firm</label>
                        <select id="memberFirm" name="member_firm" className="form-field select" onChange={onValueChange}>
                            <option key="" value="">Select one</option>
                            {!!memberFirms && memberFirms.map((firm) => (
                                <option key={firm.id} value={firm.id}>{firm.business_name}</option>
                            ))}
                        </select>
                    </div>
                    <div className="form-group col-md-4">
                        <label htmlFor="receivedFrom" className="form-label">Received from</label>
                        <select id="receivedFrom" name="received_from" className="form-field select" onChange={onValueChange}>
                            <option key="" value="">All</option>
                            {!!receivedFrom && receivedFrom.map((email) => (
                                <option key={email} value={email}>{email}</option>
                            ))}
                        </select>
                    </div>
                    <div className="form-group col-md-2">
                        <label htmlFor="dateFrom" className="form-label">Date from</label>
                        <DatePickerField
                            name="date_from"
                            className="form-field date"
                            id="dateFrom"
                            value={queryParams.date_from}
                            onChange={onValueChange}
                            
                        />
                    </div>
                    <div className="form-group col-md-2">
                        <label htmlFor="dateTo" className="form-label">Date to</label>
                        <DatePickerField
                            name="date_to"
                            className="form-field date"
                            id="dateTo"
                            value={queryParams.date_to}
                            onChange={onValueChange}
                            
                        />
                    </div>
                    <div className="form-group col-md-12">
                        <div className="form-radio-group form-radio-group-horizontal"
                            role="group"
                            aria-labelledby="fileTypeId">
                                    <span className="form-radio-group-label form-label mb-0">
                                        Select File Type
                                    </span>
                            <div className="form-radio-group-body radio-box">
                                {fileTypeIds.map((ft) => (
                                    <div className="form-radio form-radio-horizontal" key={ft.key}>
                                        <label className="form-radio-label">
                                            <input
                                                type="radio"
                                                name="file_type"
                                                className="form-radio-input"
                                                value={ft.key}
                                                id={"radio-file-type-" + ft.key.toString() || 0}
                                                checked={queryParams.file_type === (ft.key || undefined)}
                                                onChange={onValueChange}
                                            />
                                            <span
                                                className="form-radio-text"
                                                htmlFor={"radio-file-type-" + ft.key.toString() || 0}>
                                                {ft.value}
                                            </span>
                                        </label>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="form-group col-md-12 text-center">
                        <button className="btn btn-secondary" onClick={resetQueryParams}>Clear selection</button>
                    </div>
                </div>
                <hr className="mt-2 mb-5" />
                {!!results && (<div className="results">
                    <div className="table table-total mb-5" role="table">
                        <div className="table-header" role="rowgroup">
                            <div className="table-row" role="row">
                                <div className="table-col table-col-date-first" role="columnheader">
                                    <span className="table-col-header"><span>1st file rcvd</span></span>
                                </div>
                                <div className="table-col table-col-date-last" role="columnheader">
                                    <span className="table-col-header"><span>Last file rcvd</span></span>
                                </div>
                            </div>
                        </div>
                        <div className="table-body" role="rowgroup">
                            <div className="table-row" role="row">
                                <div className="table-col table-col-date-first" data-title="1st file rcvd" role="cell">
                                    {results.first_date || ""}
                                </div>
                                <div className="table-col table-col-date-last" data-title="Last file rcvd" role="cell">
                                    {results.last_date || ""}
                                </div>
                            </div>
                        </div>
                    </div>
                    <p>Selection result summary: Click <u>underlined</u> to expand:</p>
                    <div className="table table-subtotal mb-5" role="table">
                        <div className="table-header" role="rowgroup">
                            <div className="table-row" role="row">
                                <div className="table-col table-col-total-files" role="columnheader">
                                    <span className="table-col-header"><span>Total files</span></span>
                                </div>
                                <div className="table-col table-col-passed" role="columnheader">
                                    <span className="table-col-header"><span>Passed</span></span>
                                </div>
                                <div className="table-col table-col-date-last" role="columnheader">
                                    <span className="table-col-header"><span>Failed</span></span>
                                </div>
                            </div>
                        </div>
                        <div className="table-body" role="rowgroup">
                            <div className="table-row" role="row">
                                <div className="table-col table-col-total-files" data-title="Total files" role="cell">
                                    <Link to={urlToResults()}>{results.total}</Link>
                                </div>
                                <div className="table-col table-col-passed" data-title="Passed" role="cell">
                                    <Link to={urlToResults({results: 'passed'})}>{results.passed}</Link>
                                </div>
                                <div className="table-col table-col-failed" data-title="Failed" role="cell">
                                    <Link to={urlToResults({results: 'fail'})}>{results.failed}</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="table table-summary-results mb-5" role="table">
                        <div className="table-body" role="rowgroup">
                            {results.file_type_1_total !== undefined && <div className="table-row" role="row">
                                <div className="table-col table-col-total-files" data-title="Total files" role="cell">
                                    <Link to={urlToResults({file_type: 1})}>Entity</Link>
                                </div>
                                <div className="table-col table-col-total-files" data-title="Total files" role="cell">
                                    <Link to={urlToResults({file_type: 1})}>{results.file_type_1_total}</Link>
                                </div>
                                <div className="table-col table-col-passed" data-title="Passed" role="cell">
                                    <Link to={urlToResults({file_type: 1, results: 'passed'})}>
                                        {results.file_type_1_total - results.file_type_1_failed}
                                    </Link>
                                </div>
                                <div className="table-col table-col-failed" data-title="Failed" role="cell">
                                    <Link to={urlToResults({file_type: 1, results: 'fail'})}>
                                        {results.file_type_1_failed}
                                    </Link>
                                </div>
                            </div>}
                            {results.file_type_2_total !== undefined && <div className="table-row" role="row">
                                <div className="table-col table-col-total-files" data-title="Total files" role="cell">
                                    <Link to={urlToResults({file_type: 2})}>Client Engagement</Link>
                                </div>
                                <div className="table-col table-col-total-files" data-title="Total files" role="cell">
                                    <Link to={urlToResults({file_type: 2})}>
                                        {results.file_type_2_total}
                                    </Link>
                                </div>
                                <div className="table-col table-col-passed" data-title="Passed" role="cell">
                                    <Link to={urlToResults({file_type: 2, results: 'passed'})}>
                                        {results.file_type_2_total - results.file_type_2_failed}
                                    </Link>
                                </div>
                                <div className="table-col table-col-failed" data-title="Failed" role="cell">
                                    <Link to={urlToResults({file_type: 2, results: 'fail'})}>
                                        {results.file_type_2_failed}
                                    </Link>
                                </div>
                            </div>}
                            {results.file_type_3_total !== undefined && <div className="table-row" role="row">
                                <div className="table-col table-col-total-files" data-title="Total files" role="cell">
                                    <Link to={urlToResults({file_type: 3})}>Business Relationship</Link>
                                </div>
                                <div className="table-col table-col-total-files" data-title="Total files" role="cell">
                                    <Link to={urlToResults({file_type: 3})}>{results.file_type_3_total}</Link>
                                </div>
                                <div className="table-col table-col-passed" data-title="Passed" role="cell">
                                    <Link to={urlToResults({file_type: 3, results: 'passed'})}>
                                        {results.file_type_3_total - results.file_type_3_failed}
                                    </Link>
                                </div>
                                <div className="table-col table-col-failed" data-title="Failed" role="cell">
                                    <Link to={urlToResults({file_type: 3, results: 'fail'})}>
                                        {results.file_type_3_failed}
                                    </Link>
                                </div>
                            </div>}
                            {results.file_type_4_total !== undefined && <div className="table-row" role="row">
                                <div className="table-col table-col-total-files" data-title="Total files" role="cell">
                                    <Link to={urlToResults({file_type: 4})}>Personnel Relationship</Link>
                                </div>
                                <div className="table-col table-col-total-files" data-title="Total files" role="cell">
                                    <Link to={urlToResults({file_type: 4})}>{results.file_type_4_total}</Link>
                                </div>
                                <div className="table-col table-col-passed" data-title="Passed" role="cell">
                                    <Link to={urlToResults({file_type: 4, results: 'passed'})}>
                                        {results.file_type_4_total - results.file_type_4_failed}
                                    </Link>
                                </div>
                                <div className="table-col table-col-failed" data-title="Failed" role="cell">
                                    <Link to={urlToResults({file_type: 4, results: 'fail'})}>
                                        {results.file_type_4_failed}
                                    </Link>
                                </div>
                            </div>}
                        </div>
                    </div>
                </div>)}
            </div>
        </React.Fragment>
    );
}