export default function FileImportPreview({ data, _, previewFile }) {
  let headers = [
    "ID",
    "Type",
    "Legal Name",
    "Person Salutation",
    "Person First Name",
    "Person Last Name",
    "Person Current Employer",
    "Local Govt ID",
    "Local Govt Name",
    "Bvd ID",
    "Public Interest Entity",
    "Listed In Stock Exchange",
    "Stock Exchange Name",
    "Listed Security Type",
    "Ticker Symbol",
    "Website",
    "Person In Financial Oversight Role",
    "Address Type",
    "Country",
    "Province",
    "City",
    "Address Line 1",
    "Address Line 2",
    "Address Line 3",
    "Address Line 4",
    "Postal Code",
    "Comments",
    "Transaction Type",
    "Confidential Indicator",
  ];
  const maxColLength = 25;
  headers = data.length ? data[0] : headers;
  const colEmpty = Array(headers.length).fill(true);
  data.forEach((row) => {
    row.forEach((col, index) => {
      if (col !== "") colEmpty[index] = false;
    });
  });

  return data.length ? (
    <div className={previewFile ? "" : "hidden"}>
      <div className="datagrid-header">
        <h2 className="h4 datagrid-header-title" id="user_table_desc">
          {data.length} Rows (including header)
        </h2>
      </div>

      <div className="datagrid-content">
        <div className="table" role="table" aria-describedby="user_table_desc">
          <div className="table-header" role="rowgroup">
            <div className="table-row" role="row">
              <div className="table-col" role="columnheader">
                <span className="table-col-header active">
                  <span>#</span>
                  <i className="icon icon-d-block icon-arrow-draw-down pl-2" />
                </span>
              </div>
              {headers.map((header, index) => (
                <div
                  key={index}
                  className={
                    colEmpty[index]
                      ? "table-col col-empty"
                      : "table-col preview-table-col"
                  }
                  role="columnheader"
                >
                  <span>
                    <span>{header}</span>
                  </span>
                </div>
              ))}
            </div>
          </div>
          <div className="table-body" role="rowgroup">
            {data.slice(1).map((row, rowIndex) => (
              <div
                className="table-row table-row-hoverable"
                role="row"
                key={rowIndex}
              >
                <div
                  className="table-col preview-table-users-col-row"
                  role="cell"
                >
                  <div className="user-card-small-text">
                    <span className="user-card-small-name">{rowIndex+1}</span>
                  </div>
                </div>
                {row.map((column, colIndex) => (
                  <div
                    className={
                      colEmpty[colIndex]
                        ? "table-col preview-table-col col-empty"
                        : "table-col preview-table-col"
                    }
                    role="cell"
                    key={colIndex}
                  >
                    <div className="user-card-small-text">
                      <span className="user-card-small-name">
                        {column.slice(0, maxColLength) +
                          (column.length > maxColLength ? "..." : "")}
                      </span>
                    </div>
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  ) : "";
}
