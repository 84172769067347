import React, {useState} from "react";
import Loading from "../../common/Loading";
import DatePickerField from "../../common/DatePickerField";
import "./ResultFiles.css";
import Pagination from "../../common/Pagination";
import {Link, useSearchParams} from "react-router-dom";
import API from "../../common/API";

export default function ResultFiles() {
    const initialValues = {
        member_firm: "",
        received_from: "",
        date_from: "",
        date_to: "",
        file_type: undefined,
    };

    const fileTypeIds = [
        {key: "", value: "All"},
        {key: "1", value: "Entity"},
        {key: "2", value: "Client Engagement"},
        {key: "3", value: "Business Relationship"},
        {key: "4", value: "Personnel Relationship"},
    ];

    const [init, setInit] = useState(false);
    const [memberFirms, setMemberFirms] = useState([]);
    const [receivedFrom, setReceivedFrom] = useState([]);
    const [loading, setLoading] = useState(true);
    const [results, setResults] = useState([]);
    const [queryParams, setQueryParams] = useState(initialValues);
    const [searchParams, setSearchParams] = useSearchParams();

    const getResults = (params) => {
        setLoading(true);
        params = (params) ? {...queryParams, ...params} : queryParams;
        params = Object.fromEntries(Object.entries(params).filter(([_, v]) => ![null, undefined, ""].includes(v)));
        setQueryParams(params);
        setSearchParams(new URLSearchParams(params));
        localStorage.setItem('resultFilesParams', JSON.stringify(params));
        API.get(`member-files`, params)
            .then((response) => {
                setResults(response);
                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
            });
    }

    const onValueChange = (event) => {
        getResults({[event.target.name]: event.target.value, page: 1});
    }

    const getFileTypeIdName = (file) => {
        if (file.file_type_id) {
            const el = fileTypeIds.find((e) => e.key === file.file_type_id.toString())
            return el ? el.value : "";
        }
        return "";
    }

    const resetQueryParams = () => {
        setQueryParams(initialValues);
        setResults(false);
    }

    const onPageChange = (value) => {
        getResults({page: value})
    }

    if (!init) {
        const params = Object.fromEntries(searchParams);
        setQueryParams(params);
        API.get(`member-firm`)
            .then((response) => {
                setMemberFirms(response);
                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
            });
        setLoading(true);
        API.get(`member-files/received-from`)
            .then((response) => {
                setReceivedFrom(response);
                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
            });
        if (Object.keys(params).length) {
            getResults(params);
        }
        setInit(true);
    }

    return (
        <React.Fragment>
            {loading && <Loading/>}
            <div className="content-header">
                <h1 className="h2 content-header-title">Data profiler &mdash; Member firm file level results</h1>
            </div>
            <div className="container">
                <div className="row">
                    <div className="form-group col-md-4">
                        <label htmlFor="memberFirm" className="form-label">Member Firm</label>
                        <select
                            id="memberFirm"
                            name="member_firm"
                            className="form-field select"
                            value={queryParams.member_firm}
                            onChange={onValueChange}>
                            <option key="" value="">Select one</option>
                            {memberFirms.map((firm) => (
                                <option key={firm.id} value={firm.id}>{firm.business_name}</option>
                            ))}
                        </select>
                    </div>
                    <div className="form-group col-md-4">
                        <label htmlFor="receivedFrom" className="form-label">Received from</label>
                        <select
                            id="receivedFrom"
                            name="received_from"
                            className="form-field select"
                            value={queryParams.received_from}
                            onChange={onValueChange}>
                            <option key="" value="">All</option>
                            {receivedFrom.map((email) => (
                                <option key={email} value={email}>{email}</option>
                            ))}
                        </select>
                    </div>
                    <div className="form-group col-md-2">
                        <label htmlFor="dateFrom" className="form-label">Date from</label>
                        <DatePickerField
                            name="date_from"
                            className="form-field date"
                            id="dateFrom"
                            value={queryParams.date_from}
                            onChange={onValueChange}
                        />
                    </div>
                    <div className="form-group col-md-2">
                        <label htmlFor="dateTo" className="form-label">Date to</label>
                        <DatePickerField
                            name="date_to"
                            className="form-field date"
                            id="dateTo"
                            value={queryParams.date_to}
                            onChange={onValueChange}
                        />
                    </div>
                    <div className="form-group col-md-12">
                        <div className="form-radio-group form-radio-group-horizontal"
                            role="group"
                            aria-labelledby="fileTypeId">
                                    <span className="form-radio-group-label form-label mb-0">
                                        Select File Type
                                    </span>
                            <div className="form-radio-group-body radio-box">
                                {fileTypeIds.map((ft) => (
                                    <div className="form-radio form-radio-horizontal" key={ft.key}>
                                        <label className="form-radio-label">
                                            <input
                                                type="radio"
                                                name="file_type"
                                                className="form-radio-input"
                                                value={ft.key}
                                                id={"radio-file-type-" + ft.key.toString() || 0}
                                                checked={queryParams.file_type === (ft.key || undefined)}
                                                onChange={onValueChange}
                                            />
                                            <span
                                                className="form-radio-text"
                                                htmlFor={"radio-file-type-" + ft.key.toString() || 0}>
                                                {ft.value}
                                            </span>
                                        </label>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="form-group col-md-12 text-center">
                        <button className="btn btn-secondary" onClick={resetQueryParams}>Clear selection</button>
                    </div>
                </div>
                <hr className="mt-2 mb-5" />
                {!!results.data && (<div className="results">
                    <p>Selection result: Click <u>underlined</u> to view file details</p>
                    {queryParams.member_firm && memberFirms.length && (<p>
                        You are viewing files from
                        "{memberFirms.find((e) => e.id.toString() === queryParams.member_firm).business_name}"
                    </p>)}
                    <div className="table table-files mb-5" role="table">
                        <div className="table-header" role="rowgroup">
                            <div className="table-row" role="row">
                                <div className="table-col table-col-file-id" data-title="File ID" role="columnheader">
                                    <span className="table-col-header"><span>File ID</span></span>
                                </div>
                                <div className="table-col table-col-file-type" data-title="File type" role="columnheader">
                                    <span className="table-col-header"><span>File type</span></span>
                                </div>
                                <div className="table-col table-col-file-name" data-title="File name" role="columnheader">
                                    <span className="table-col-header"><span>File name</span></span>
                                </div>
                                <div className="table-col table-col-rcvd-date" data-title="Rcvd date" role="columnheader">
                                    <span className="table-col-header"><span>Rcvd date</span></span>
                                </div>
                                <div className="table-col table-col-created" data-title="Process date" role="columnheader">
                                    <span className="table-col-header"><span>Process date</span></span>
                                </div>
                                <div className="table-col table-col-rcvd-from" data-title="Rcvd from" role="columnheader">
                                    <span className="table-col-header"><span>Rcvd from</span></span>
                                </div>
                                <div className="table-col table-col-total" data-title="Total" role="columnheader">
                                    <span className="table-col-header"><span>Total</span></span>
                                </div>
                                <div className="table-col table-col-total" data-title="Pass" role="columnheader">
                                    <span className="table-col-header"><span>Pass</span></span>
                                </div>
                                <div className="table-col table-col-total" data-title="Fail" role="columnheader">
                                    <span className="table-col-header"><span>Fail</span></span>
                                </div>
                            </div>
                        </div>
                        <div className="table-body" role="rowgroup">
                            {results.data.map((file) => (<div className="table-row" role="row" key={file.id}>
                                <div className="table-col table-col-file-id" data-title="File ID" role="cell">
                                    <Link to={`/dashboard/data-profiler/${file.id}/messages`}>{file.id}</Link>
                                </div>
                                <div className="table-col table-col-file-type" data-title="File type" role="cell">
                                    {getFileTypeIdName(file)}
                                </div>
                                <div className="table-col table-col-file-name" data-title="File type" role="cell">
                                    {file.file_name}
                                </div>
                                <div className="table-col table-col-rcvd-date" data-title="Rcvd date" role="cell">
                                    {file.received_date && file.received_date.substring(0, 10)}
                                </div>
                                <div className="table-col table-col-created" data-title="Process date" role="cell">
                                    {file.received_date && file.created_date.substring(0, 10)}
                                </div>
                                <div className="table-col table-col-rcvd-from" data-title="Rcvd from" role="cell">
                                    {file.received_from}
                                </div>
                                <div className="table-col table-col-total" data-title="Total" role="cell">
                                    {file.total}
                                </div>
                                <div className="table-col table-col-pass" data-title="Pass" role="cell">
                                    {file.passed}
                                </div>
                                <div className="table-col table-col-fail" data-title="Fail" role="cell">
                                    {file.failed}
                                </div>
                            </div>))}
                        </div>
                    </div>
                    <Pagination {...results} onPageChange={onPageChange} />
                </div>)}
            </div>
        </React.Fragment>
    );
}