import { mixed, object, string, date, number } from "yup";

export const members = [
  {
    id: 0,
    firm_id: 1,
    first_name: "Himanshu",
    last_name: "Yadav",
  },
  {
    id: 1,
    firm_id: 2,
    first_name: "Ousmane",
    last_name: "Traore",
  },
  {
    id: 2,
    firm_id: 0,
    first_name: "John",
    last_name: "Doe",
  },
];

export const profilers = [
  {
    id: 0,
    firm_id: 1,
    first_name: "Marie",
    last_name: "Ridgeway",
    email: "Marie.Ridgeway@gtil.gt.com",
  },
  {
    id: 1,
    firm_id: 2,
    first_name: "Manoj",
    last_name: "Yadav",
    email: "Manoj.Yadav@gtil.gt.com",
  },
  {
    id: 2,
    firm_id: 0,
    first_name: "Tarcy",
    last_name: "Finn",
    email: "Tarcy.Finn@gti.gt.com",
  },
  {
    id: 3,
    firm_id: 0,
    first_name: "Angel",
    last_name: "Vargas",
    email: "Angel.vargas@gti.gt.com",
  },
];

export const importFormSchema = object({
  fileTypeId: string().required("Import Type is Required"),
  memberFirm: number().required("Member Firm is a required field"),
  receivedFrom: string().email().required("Member received from is required"), // TODO: use yup.when to validate the member is from the member country
  receivedDate: date().required("Received Date is Required"),
  processedBy: string().required("Profiler Name is Required"),
  file: mixed().required("File is required"),
});
