import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const DatePickerField = ({ name, value, onChange }) => {
    return (
        <DatePicker
            className="form-field"
            name={name}
            selected={(value && new Date(value)) || null}
            onChange={date => {
                onChange({target: {name: name, value: date.toISOString().substring(0, 10)}});
            }}
        />
    );
};

export default DatePickerField;