export default class API {
    static getFetch = (method, path, body, headers = {}, cache = false) => {
        const baseUrl = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL + '/' : '/';
        const token = localStorage.getItem('userToken');
        if (token) {
            headers = {...headers, 'Authorization': `Bearer ${token}`}
        }
        if (!(body instanceof FormData)) {
            headers = {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                ...headers
            };
            body = JSON.stringify(body);
        }
        return fetch(baseUrl + path, {
            method: method,
            cache: cache ? 'force-cache' : 'no-cache',
            headers: headers,
            body: body,
        });
    }

    static api = (method, path, body, headers, cache, responseType) => {
        if (responseType === "file") {
            headers = {...headers, "Accept": "application/octet-stream"}
        }
        return API.getFetch(method, path, body, headers, cache).then(response => response).then((response) => {
            if (response.status === 401 && window.location.pathname !== '/login') {
                localStorage.removeItem('userToken');
                window.location = '/login';
            } else if (response.status >= 500) {
                return {"detail": "Internal server error"};
            } if (responseType === "file") {
                return response;
            }
            return response.json();
        }).catch((response) => {
            return response;
        });
    }

    static get = (path, body, headers, cache) => {
        const params = new URLSearchParams(body).toString();
        if (params) {
            path += "?" + params;
        }
        return API.api('GET', path, undefined, headers, cache);
    }

    static post = (path, body, headers, cache, responseType) => {
        return API.api('POST', path, body, headers, cache, responseType);
    }
    static put = (path, body, headers, cache, responseType) => {
        return API.api('PUT', path, body, headers, cache, responseType);
    }
    static delete = (path, body, headers, cache, responseType) => {
        return API.api('PUT', path, body, headers, cache, responseType);
    }
    static download = (path, filename, body, headers, cache) => {
        return API.api("GET", path, body, headers, cache, "file").then((response) => {
            return response.blob().then(blob => {
                const a = document.createElement("a");
                a.href = URL.createObjectURL(blob);
                a.setAttribute("download", filename);
                a.click();
            });
        });
    }
    static auth = (credentials) => {
        const body = new FormData();
        for (const key in credentials) {
            body.append(key, credentials[key]);
        }
        return API.post('login', body).then((response) => {
            if (response['access_token']) {
                localStorage.setItem('userToken', response['access_token']);
                return true;
            }
            return false;
        });
    }
    static check = () => {
        return API.get('user/account').then((response) => {
            return !!response['username'];
        });
    }
    static logout = () => {
        localStorage.removeItem('userToken');
        window.location = '/login';
    }
}