import { Formik, Form, Field, ErrorMessage } from "formik";
import React, { useState } from "react";
import { parse } from "papaparse";
import {
  profilers,
  importFormSchema,
} from "../../../data/data";
import FileImportPreview from "../../common/FileImportPreview";
import FileModal from "../../common/FileModal";
import "./ImportFilesPage.css";
import Loading from "../../common/Loading";
import API from "../../common/API";

export default function ImportFilesPage() {
  // const [user] = useOutletContext();
  const [init, setInit] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [file, setFile] = useState({});
  const [parsedFile, setParsedFile] = useState([]);
  const [previewFile, setPreviewFile] = useState(false);
  const [fileErrors, setFileErrors] = useState([]);
  const [memberFirms, setMemberFirms] = useState([]);
  const [formSent, setFormSent] = useState(false);
  const [formError, setFormError] = useState(false);
  const [loading, setLoading] = useState(false)

  let setField = null;

  const setFieldFunc = (func) => {
    setField = func;
    return "";
  };

  const handleSave = (acceptedFile) => {
    setModalShow(false);
    setFile(acceptedFile);
    setField("file", file);
    setPreviewFile(true);
    parse(acceptedFile, {
      skipEmptyLines: true,
      complete: function (results) {
        setParsedFile(results.data);
      },
      error: (err) => {
        console.debug(err);
      },
    });
  };

  const formSubmit = (values, actions) => {
    if (file.path === undefined) {
      return alert("file required");
    }
    let formData = new FormData();
    formData.append("file_type_id", values.fileTypeId);
    formData.append("member_firm_id", values.memberFirm);
    formData.append("received_from", values.receivedFrom);
    formData.append("processed_by", values.processedBy);
    formData.append("received_date", values.receivedDate);
    formData.append("file", file);

    setLoading(true);
    API.post('member-file', formData)
      .then((response) => {
        const data = response;
        setFileErrors(data.row_errors);
        if (data && data.detail && typeof data.detail === "string") {
          setFormError(data.detail);
        } else {
          setFormSent(true);
          setFormError(false);
        }
        actions.resetForm();
        setFile({});
        setParsedFile([]);
        setLoading(false);
      })
      .catch((error) => {
        if (error.data && error.data.detail && typeof error.data.detail === "string") {
          setFormError(error.data.detail);
        }
        setFormSent(false);
        setLoading(false);
      });
  };

  if (!init) {
    API.get(`member-firm`).then((result) => {
      setMemberFirms(result);
    });
    setInit(true);
  }

  const initialValues = {
    fileTypeId: "1",
    memberFirm: "",
    receivedFrom: "",
    processedBy: "",
    receivedDate: new Date().toLocaleDateString("en-CA"),
    file: null,
  };

  const fileTypeIds = [
    { key: "1", value: "Entity" },
    { key: "2", value: "Client Engagement" },
    { key: "3", value: "Business Relationship" },
    { key: "4", value: "Personnel Relationship" },
  ];

  return (
    <React.Fragment>
      {loading && <Loading/>}
      <div className="content-header">
        <h1 className="h2 content-header-title">Onboard A New File</h1>
      </div>
      <div>
        {formSent && <div className="alert-box">
          <p className="text-body">
          <span className="status status-positive">
            <span className="status-icon">
              <i className="icon icon-check-mark" aria-hidden="true" />
            </span>
            <span className="status-text">Your file has been successfully processed</span>
          </span>
          </p>
        </div>}
        {formError && <div className="alert-box">
          <p className="text-body">
          <span className="status status-alert">
            <span className="status-icon">
              <i className="icon icon-check-mark" aria-hidden="true" />
            </span>
            <span className="status-text">We have got an error: {formError}</span>
          </span>
          </p>
        </div>}
        <Formik
          initialValues={initialValues}
          validationSchema={importFormSchema.shape()}
          onSubmit={formSubmit}
        >
          {({ touched, errors, setFieldValue }) => (
            <Form>
              {setFieldFunc(setFieldValue)}
              <div className="form-group">
                <div
                  className="form-radio-group form-radio-group-horizontal"
                  role="group"
                  aria-labelledby="fileTypeId"
                >
                  <span className="form-radio-group-label form-label mb-0">
                    Select File Type to be Uploaded
                  </span>
                  <div className="form-radio-group-body radio-box">
                    {fileTypeIds.map((fileTypeId, index) => (
                      <div
                        className="form-radio form-radio-horizontal"
                        key={index}
                      >
                        <label className="form-radio-label">
                          <Field
                            type="radio"
                            name="fileTypeId"
                            value={fileTypeId.key}
                            id={"radio-" + fileTypeId.value}
                            className={
                              touched.processedBy &&
                              errors.processedBy
                                ? "form-field form-radio-input is-invalid"
                                : "form-field form-radio-input"
                            }
                          />
                          <span
                            className="form-radio-text"
                            htmlFor={"radio-" + fileTypeId.value}
                          >
                            {fileTypeId.value}
                          </span>
                        </label>
                      </div>
                    ))}
                  </div>
                  <ErrorMessage
                    name="fileTypeId"
                    component="span"
                    className="form-feedback is-invalid"
                  />
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="memberFirm" className="form-label">
                  Member Firm
                </label>
                <Field
                  component="select"
                  name="memberFirm"
                  className={
                    touched.memberFirm && errors.memberFirm
                      ? "form-field select is-invalid"
                      : "form-field select"
                  }
                >
                  <option key="" value="" />
                  {!!memberFirms && memberFirms.map((firm) => (
                    <option key={firm.id} value={firm.id}>
                      {firm.business_name}
                    </option>
                  ))}
                </Field>
                <ErrorMessage
                  name="memberFirm"
                  component="span"
                  className="form-feedback is-invalid"
                />
              </div>
              <div className="form-group">
                <label htmlFor="receivedFrom" className="form-label">
                  Received From
                </label>
                <Field
                  type="email"
                  name="receivedFrom"
                  className={
                    touched.receivedFrom && errors.receivedFrom
                      ? "form-field is-invalid"
                      : "form-field"
                  }
                />
                <ErrorMessage
                  name="receivedFrom"
                  component="span"
                  className="form-feedback is-invalid"
                />
              </div>
              <div className="form-group">
                <label htmlFor="processedBy" className="form-label">
                  Processed By
                </label>
                <Field
                  component="select"
                  name="processedBy"
                  className={
                    touched.processedBy && errors.processedBy
                      ? "form-field select is-invalid"
                      : "form-field select"
                  }
                >
                  <option key="" value="" />
                  {profilers.map((profiler) => (
                    <option key={profiler.email} value={profiler.email}>
                      {profiler.first_name} {profiler.last_name}
                    </option>
                  ))}
                </Field>
                <ErrorMessage
                  name="processedBy"
                  component="span"
                  className="form-feedback is-invalid"
                />
              </div>
              <div className="form-group">
                <label htmlFor="receivedDate" className="form-label">
                  Received Date
                </label>
                <Field type="date" name="receivedDate" className="form-field" />
                <ErrorMessage
                  name="receivedDate"
                  component="span"
                  className="form-feedback is-invalid"
                />
              </div>

              <div className="form-group row">
                <div className="col-9 choose-file-box">
                  <div className="row w-100">
                    <div className="col-4">
                      <button
                        type="button"
                        className="btn btn-primary col"
                        onClick={() => setModalShow(true)}
                      >
                        Choose File
                      </button>
                    </div>
                    <div className="col-8 p-2 selected-file-box text-center">
                      <span className="mt-3">
                        {file.path || "No File Selected"}
                      </span>
                      <ErrorMessage
                        name="file"
                        component="span"
                        className="form-feedback is-invalid"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-3">
                  <button
                    type="buttton"
                    className="btn btn-primary w-100"
                    disabled={file.path === undefined}
                    onClick={(e) => {
                      e.preventDefault();
                      setPreviewFile(!previewFile);
                    }}
                  >
                    {previewFile ? "Hide Preview" : "Preview File"}
                  </button>
                  <button
                    type="submit"
                    className="btn btn-primary w-100 mt-2"
                    disabled={loading}
                  >
                    Accept File
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>

      <FileImportPreview
        data={parsedFile}
        errors={fileErrors}
        previewFile={previewFile}
      />
      <FileModal
        title="Add File"
        show={modalShow}
        handleCancel={() => setModalShow(false)}
        handleSave={handleSave}
      />
    </React.Fragment>
  );
}
