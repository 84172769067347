import React, {useState} from "react";
import "./LoginPage.css";
import API from "../common/API";

export default function LoginPage() {
  const [init, setInit] = useState(false);
  const [credentials, setCredentials] = useState({username: '', password: ''});

  if (!init) {
    API.check().then(isAuth => {
      if (isAuth) {
        window.location = '/dashboard/data-profiler/result-summary';
      }
    });
    setInit(true);
  }

  const updateCredentials = (e) => {
    setCredentials({...credentials, [e.target.name]: e.target.value});
  }

  const loginAction = (e) => {
    e.preventDefault();
    return API.auth(credentials).then((isAuth) => {
      if (isAuth) {
        window.location = '/dashboard/data-profiler/result-summary';
      } else {
        e.target.classList.add("login-page-form-invalid");
        setTimeout(() => e.target.classList.remove("login-page-form-invalid"), 1000);
      }
      setCredentials({username: '', password: ''});
    });
  }

  return (
      <React.Fragment>
        <header className="navbar">
          <div className="navbar-top-bar">
            <div className="container">
              <div className="navbar-top-bar-container">
                <div className="navbar-top-bar-brand">
                  <a href="#">
                    <img
                        src="/img/logo/Grant-Thorton.svg"
                        alt="Grant Thornton logo"
                        className="navbar-top-bar-brand-img"
                    />
                  </a>
                </div>
                <div className="navbar-top-bar-content">
                  <div className="navbar-top-bar-aside"></div>
                  <div className="navbar-top-bar-main">
                    <div className="navbar-top-bar-col">
                      <ul
                          className="nav navbar-top-bar-nav-lang"
                          aria-label="Wybór języka"
                      >
                        <li>
                          <a href="#">PL</a>
                        </li>
                        <li>
                          <a href="#" className="active">
                            EN
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>

        <main className="container-app" id="content">
          <div
              className="login-page-bkg"
              style={{ backgroundImage: "url(/img/login-bkg.png)" }}
          ></div>

          <div className="container">
            <div className="login-page-content login-page-content-pt">
              <div className="row">
                <div className="col-sm-7">
                  <div className="login-page-article">
                    <h1>
                      <span className="d-block">Log in to</span>GT Global
                      Relationship Web Portal
                    </h1>
                    <p className="text-base">
                    <span className="d-block">
                      Our portal will help you and your company work with us
                      more efficiently.
                    </span>
                      <span className="d-block">
                      Sign in or register to gain access to knowledge, projects
                      and tools.
                    </span>
                    </p>
                  </div>
                </div>
                <div className="col-sm-5">
                  <form className="login-page-form" method="POST" onSubmit={loginAction}>
                    <div className="form-group">
                      <label htmlFor="formField1" className="form-label">
                        E-mail
                      </label>
                      <input
                          type="email"
                          required
                          className="form-field"
                          placeholder="Type e-mail"
                          name="username"
                          id="username"
                          value={credentials.username}
                          onChange={updateCredentials}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="formField2" className="form-label">
                        Password
                      </label>
                      <input
                          type="password"
                          required
                          className="form-field"
                          placeholder="Type password"
                          name="password"
                          id="password"
                          value={credentials.password}
                          onChange={updateCredentials}
                      />
                    </div>
                    <div className="form-group">
                      <a href="#" className="text-smallest link">
                        <span>Forgot password?</span>
                      </a>
                    </div>
                    <div className="form-group form-group-action ">
                      <button className="btn btn-primary btn-block-xs">
                        Login
                      </button>
                    </div>
                    <hr />
                    <p className="login-page-form-register-link">
                      Don’t have an account yet?{" "}
                      <a href="#" className="link">
                        <span>Register</span>
                      </a>
                    </p>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </main>
      </React.Fragment>
  );
}
