import React from "react";
import "./Reports.css";

export default function ReportsPage() {
  return (
    <React.Fragment>
      <div className="content-header">
        <h4 className="h4 content-header-title bold">
          Data Profiling Activity Summary Stats
        </h4>
      </div>

      <div className="row">
        <div className="col">
          <div>
            <span className="status status-positive">
              <span className="status-icon">
                <i className="icon icon-check-mark" aria-hidden="true"></i>
              </span>
              <span className="status-text">
                <span className="text-underline">120</span> of 140 member firms
                have compelted Mapping sessions with GRD team
              </span>
            </span>
          </div>

          <div>
            <span className="status status-warning">
              <span className="status-icon">
                <i className="icon icon-check-mark" aria-hidden="true"></i>
              </span>
              <span className="status-text">
                <span className="text-underline">66</span> of 140 member firms
                have submitted Entity records
              </span>
            </span>
          </div>

          <div>
            <span className="status status-warning">
              <span className="status-icon">
                <i className="icon icon-check-mark" aria-hidden="true"></i>
              </span>
              <span className="status-text">
                <span className="text-underline">61</span> of 140 member firms
                have submitted Client Engagement records
              </span>
            </span>
          </div>

          <div>
            <span className="status status-alert">
              <span className="status-icon">
                <i className="icon icon-check-mark" aria-hidden="true"></i>
              </span>
              <span className="status-text">
                <span className="text-underline">33</span> of 140 member firms
                have submitted Business relationship records
              </span>
            </span>
          </div>

          <div>
            <span className="status status-alert">
              <span className="status-icon">
                <i className="icon icon-check-mark" aria-hidden="true"></i>
              </span>
              <span className="status-text">
                <span className="text-underline">27</span> of 140 member firms
                have submitted Personnel relationship records
              </span>
            </span>
          </div>
        </div>
      </div>

      <div className="row mt-5">
        <div className="col">
          <div>
            <span className="status status-positive">
              <span className="status-icon">
                <i className="icon icon-check-mark" aria-hidden="true"></i>
              </span>
              <span className="status-text">
                <span className="text-underline">60</span> of 66 member firms
                have successful results for Entity records
              </span>
            </span>
          </div>

          <div>
            <span className="status status-alert">
              <span className="status-icon">
                <i className="icon icon-check-mark" aria-hidden="true"></i>
              </span>
              <span className="status-text">
                <span className="text-underline">18</span> of 61 member firms
                have successful results for Client engagement records
              </span>
            </span>
          </div>

          <div>
            <span className="status status-alert">
              <span className="status-icon">
                <i className="icon icon-check-mark" aria-hidden="true"></i>
              </span>
              <span className="status-text">
                <span className="text-underline">8</span> of 22 member firms
                have submitted Business relationship records
              </span>
            </span>
          </div>

          <div>
            <span className="status status-warning">
              <span className="status-icon">
                <i className="icon icon-check-mark" aria-hidden="true"></i>
              </span>
              <span className="status-text">
                <span className="text-underline">16</span> of 27 member firms
                have submitted Personnel relationship records
              </span>
            </span>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
