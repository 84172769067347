import React, { useState } from "react";
import {Link, useParams, useSearchParams} from "react-router-dom";
import "./MessagesPage.css";
import Loading from "../../common/Loading";
import Pagination from "../../common/Pagination";
import FileSummary from "./FileSummary";
import API from "../../common/API";

export default function MessagesPage() {
    // const [user] = useOutletContext();
    const { fileId } = useParams();
    const [init, setInit] = useState(false);
    const [loading, setLoading] = useState(true);
    const [file, setFile] = useState(null);
    const [returnCodes, setReturnCodes] = useState([]);
    const [messages, setMessages] = useState([]);
    const [queryParams, setQueryParams] = useState({});
    const [searchParams, setSearchParams] = useSearchParams();

    const resultMark = (category, label) => {
        const className = (category === "Error") ? "status status-alert" : "status status-warning";
        return (<span className={className}>
            <span className="status-icon">
                <i className="icon icon-exclamation-mark" aria-hidden="true"></i>
            </span>
            {label && <span className="status-text">{label}</span>}
        </span>);
    };

    const getReturnCodes = async () => {
        setLoading(true);
        API.get(`member-files/return-codes`)
            .then((response) => {
                setReturnCodes(response);
                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
            });
    }

    const getFileName = () => {
        return `${file.file_name.split(".").slice(0, -1).join(".")}_${file.created_date.substring(0, 10)}_${file.id}.csv`;
    }

    const getMessages = (fileId, params) => {
        setLoading(true);
        params = (params) ? {...queryParams, ...params} : queryParams;
        localStorage.setItem('messagesParams', JSON.stringify(params));
        setQueryParams(params);
        setSearchParams(new URLSearchParams(params));
        API.get(`member-files/${fileId}/messages`, params)
            .then((response) => {
                setMessages(response);
                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
            });
    }

    const refreshMessages = () => {
        const params = {...queryParams, ...{category: null, page: 1}};
        getMessages(fileId, params);
    }

    const onValueChange = (event) => {
        getMessages(fileId, {[event.target.name]: event.target.value, page: 1});
    }

    const onPageChange = (value) => {
        getMessages(fileId, {page: value})
    }

    const getReturnCodeByMessage = (message) => {
        let output = {
            "category": "",
            "description": "",
        }
        return {...output, ...returnCodes.find((e) => message.return_code === e.id)};
    }

    const getBackParams = () => {
        const params = localStorage.getItem('resultFilesParams');
        return params ? "?" + new URLSearchParams(JSON.parse(params)).toString() : "";
    }

    if (fileId && !init) {
        const params = Object.fromEntries(searchParams);
        setQueryParams(params);
        API.get(`member-files/${fileId}`)
            .then((response) => {
                setFile(response);
                getReturnCodes().then(() => getMessages(fileId, params));
                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
            });
        setInit(true);
    }

    return (
    <React.Fragment>
      {loading && <Loading/>}
      <div className="content-header">
        <h1 className="h2 content-header-title">Data profiler &mdash; File summary by msg</h1>
      </div>
      <div hidden={!loading && !!file}>
          Could not find the file by ID
      </div>
      {!!file && <div className={"container"}>
          <FileSummary file={file} />
          <div className="row">
              <div className="col-6 text-right">
                  <Link to={`/dashboard/data-profiler/result-files${getBackParams()}`} className="btn btn-secondary mt-5">
                      Back to results
                  </Link>
              </div>
              <div className="col-6 text-left">
                  <button
                      onClick={() => API.download(`member-files/${fileId}/results/csv`, getFileName())}
                      className="btn btn-primary mt-5">
                      Full result Download
                  </button>
              </div>
          </div>
          <hr className="mt-5 mb-5" />
          <div className="row">
              <div className="col-6">
                  <div className="form-radio-group form-radio-group-horizontal" role="group"
                       aria-labelledby="radio-group-label-3">
                      <span className="form-radio-group-label" id="radio-group-label-3">Select msg type to view</span>
                      <div className="form-radio-group-body">
                          <div className="form-radio form-radio-horizontal">
                              <label className="form-radio-label">
                                  <input
                                      type="radio"
                                      className="form-radio-input"
                                      name="category"
                                      value=""
                                      id="msg-type-all"
                                      checked={!queryParams.category}
                                      onChange={onValueChange}
                                  />
                                  <span className="form-radio-text" htmlFor="msg-type-all">All</span>
                              </label>
                          </div>
                          <div className="form-radio form-radio-horizontal">
                              <label className="form-radio-label">
                                  <input
                                      type="radio"
                                      className="form-radio-input"
                                      name="category"
                                      value="Warning"
                                      id="msg-type-warning"
                                      checked={queryParams.category === 'Warning'}
                                      onChange={onValueChange}
                                  />
                                  <span className="form-radio-text" htmlFor="msg-type-warning">Warnings</span>
                              </label>
                          </div>
                          <div className="form-radio form-radio-horizontal">
                              <label className="form-radio-label">
                                  <input
                                      type="radio"
                                      className="form-radio-input"
                                      name="category"
                                      value="Error"
                                      id="msg-type-error"
                                      checked={queryParams.category === 'Error'}
                                      onChange={onValueChange}
                                  />
                                  <span className="form-radio-text" htmlFor="msg-type-error">Errors</span>
                              </label>
                          </div>
                      </div>
                  </div>
              </div>
              <div className="col-6">
                  <button className="btn btn-primary" onClick={refreshMessages}>Refresh list</button>
              </div>
          </div>
          <hr className="mt-5 mb-5" />
          <div className="row">
              <div className="col-10">
                  <h3>Selected MSG list</h3>
                  <div className="table table-messages" role="table">
                      <div className="table-header" role="rowgroup">
                          <div className="table-row" role="row">
                              <div className="table-col table-messages-msg-id" role="columnheader">
                                  <span className="table-col-header"><span>Msg ID</span></span>
                              </div>
                              <div className="table-col table-messages-result" role="columnheader">
                                  <span className="table-col-header"><span>Result</span></span>
                              </div>
                              <div className="table-col table-messages-msg-desc" role="columnheader">
                                  <span className="table-col-header"><span>Msg desc</span></span>
                              </div>
                              <div className="table-col table-messages-rec-cnt" role="columnheader">
                                  <span className="table-col-header"><span>Rec cnt</span></span>
                              </div>
                          </div>
                      </div>
                      <div className="table-body" role="rowgroup">
                          {!!messages.data && !!messages.data.length && messages.data.map((msg, idx) => (
                              <div key={idx} className="table-row" role="row">
                                  <div className="table-col table-messages-msg-id" data-title="Msg ID" role="cell">
                                      <Link to={
                                          `/dashboard/data-profiler/${fileId}/results?return_code=${msg.return_code}`
                                      }>
                                          <span>{msg.return_code}</span>
                                      </Link>
                                  </div>
                                  <div className="table-col table-messages-result" data-title="Result" role="cell">
                                      {resultMark(getReturnCodeByMessage(msg).category)}
                                  </div>
                                  <div className="table-col table-messages-msg-desc" data-title="Msg desc" role="cell">
                                      <span>{getReturnCodeByMessage(msg).description}</span>
                                  </div>
                                  <div className="table-col table-messages-rec-cnt" data-title="Rec cnt" role="cell">
                                      <span>{msg.cnt}</span>
                                  </div>
                              </div>))
                          }
                      </div>
                  </div>
                  {!!messages.data && <Pagination {...messages} onPageChange={onPageChange} />}
              </div>
              <div className="col-2">
                  <div className="ml-2">
                      <div>{resultMark("Warning", "Warning")}</div>
                      <div>{resultMark("Error", "Error")}</div>
                  </div>
              </div>
          </div>
          <hr className="mt-5 mb-5" />
      </div>}
    </React.Fragment>
    );
}
