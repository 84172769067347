import { useState } from "react";
import { Link, NavLink, Outlet } from "react-router-dom";
import "./Dashboard.css";
import API from "./common/API";

export default function Dashboard() {
  const [user, setUser] = useState({
    name: "Manoj Yadav",
    role: "Business Analyst",
  });

  const toggleMenu = (e) => {
    e.preventDefault();
    document
      .getElementsByClassName("navbar-collapse")[0]
      .classList.toggle("open");
  };
  return (
    <div>
      <header className="navbar">
        <div className="navbar-top-bar navbar-top-bar-logged-in">
          <div className="container-fluid">
            <div className="navbar-top-bar-container">
              <div className="navbar-top-bar-brand">
                <Link to="/dashboard" title="Grant Thornton">
                  <img
                    src="/img/logo/Grant-Thorton-white.svg"
                    alt="Grant Thornton logo"
                    className="navbar-top-bar-brand-img"
                  />
                </Link>
              </div>
              <div className="navbar-top-bar-content">
                <div className="navbar-top-bar-aside">
                  <h4>Global Relationship Database Web Portal</h4>
                </div>

                <div className="navbar-top-bar-main">
                  <div className="navbar-top-bar-col navbar-top-bar-col-hidden-lg">
                    <a
                      href="#"
                      id="NavToggleMenuButton"
                      className="navbar-top-bar-toggle-btn"
                      aria-haspopup="true"
                      aria-expanded="false"
                      onClick={toggleMenu}
                    >
                      <span className="navbar-top-bar-toggle-btn-label">
                        Menu
                      </span>
                      <span className="navbar-top-bar-toggle-btn-icon">
                        <span className="bar-icon"></span>
                        <span className="bar-icon"></span>
                        <span className="bar-icon"></span>
                      </span>
                    </a>
                  </div>

                  <div className="navbar-top-bar-col navbar-top-bar-col-hidden-xs">
                    <div
                      id="header-dropdown"
                      className="dropdown dropright"
                      onClick={() =>
                        document
                          .getElementById("header-dropdown")
                          .classList.toggle("open")
                      }
                    >
                      <a
                        href="#"
                        id="dropdownUserMenuButton"
                        aria-haspopup="true"
                        aria-expanded="false"
                        className="navbar-top-bar-user-dropdown"
                      >
                        <span className="navbar-top-bar-user-dropdown-content">
                          <span className="navbar-top-bar-user-dropdown-name">
                            {user.name}
                          </span>
                          <span className="navbar-top-bar-user-dropdown-position">
                            {user.role}
                          </span>
                        </span>
                        <span className="navbar-top-bar-user-dropdown-img">
                          <img
                            src="/img/profile.webp"
                            alt=""
                            className="img-circle img-fluid"
                          />
                        </span>
                        <span className="navbar-top-bar-user-dropdown-arrow">
                          <i
                            className="icon icon-d-block icon-chevron-small-down"
                            aria-hidden="true"
                          ></i>
                        </span>
                      </a>
                      <ul
                        className="nav dropdown-menu"
                        aria-labelledby="dropdownUserMenuButton"
                      >
                        <li>
                          <a href="#">My profile</a>
                        </li>
                        <li>
                          <a onClick={API.logout}>Log out</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="navbar-top-bar-col navbar-top-bar-col-hidden-xs">
                    <a href="#" className="navbar-top-bar-link-icon">
                      <i
                        className="icon icon-d-block icon-gear"
                        aria-hidden="true"
                      ></i>
                      <span className="sr-only">Settings</span>
                    </a>
                  </div>
                  <div className="navbar-top-bar-col navbar-top-bar-col-hidden-xs">
                    <ul
                      className="nav navbar-top-bar-nav-lang"
                      aria-label="Wybór języka"
                    >
                      <li>
                        <a href="#">PL</a>
                      </li>
                      <li>
                        <a href="#" className="active">
                          EN
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="navbar-collapse" aria-labelledby="NavToggleMenuButton">
          <nav aria-label="Main menu">
            <ul className="navbar-nav">
              <span className="navbar-subtitle">GRD Data Profiler</span>
              <li>
                <NavLink to="/dashboard/import-files">
                  <span className="navbar-nav-icon">
                    <i
                      className="icon icon-d-block icon-upload-1"
                      aria-hidden="true"
                    ></i>
                  </span>
                  <span>Onboard New File</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/dashboard/import-history" style={{ pointerEvents: 'none' }} >
                  <span className="navbar-nav-icon">
                    <i
                      className="icon icon-d-block icon-documents"
                      aria-hidden="true"
                    ></i>
                  </span>
                  <span>Files History</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/dashboard/reports" style={{ pointerEvents: 'none' }}>
                  <span className="navbar-nav-icon">
                    <i
                      className="icon icon-d-block icon-document-check"
                      aria-hidden="true"
                    ></i>
                  </span>
                  <span>Reports</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/dashboard/data-profiler/result-summary">
                  <span className="navbar-nav-icon">
                    <i
                        className="icon icon-d-block icon-accounting"
                        aria-hidden="true"
                    ></i>
                  </span>
                  <span>Result Summary</span>
                </NavLink>
              </li>
              <li>
                <span className="navbar-subtitle">Readiness Evaluation</span>
                <ul className="navbar-nav">
                  <li>
                    <NavLink to="/profiler/completed" style={{ pointerEvents: 'none' }}>
                      <span className="navbar-nav-icon">
                        <i
                          className="icon icon-d-block icon-check"
                          aria-hidden="true"
                        ></i>
                      </span>
                      <span>Completed</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/profiler/in-progress" style={{ pointerEvents: 'none' }}>
                      <span className="navbar-nav-icon">
                        <i
                          className="icon icon-d-block icon-hourglass"
                          aria-hidden="true"
                        ></i>
                      </span>
                      <span>In Progress</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/profiler/awaiting" style={{ pointerEvents: 'none' }}>
                      <span className="navbar-nav-icon">
                        <i
                          className="icon icon-d-block icon-clock"
                          aria-hidden="true"
                        ></i>
                      </span>
                      <span>Awaiting Action</span>
                    </NavLink>
                  </li>
                </ul>
              </li>
              <li className="has-submenu hide-on-xl">
                <a
                  href="#"
                  id="NavSettingsMenuButton"
                  aria-haspopup="true"
                  aria-expanded="false"
                  data-target="#navbar-nav-user"
                >
                  <span className="navbar-nav-icon no-border">
                    <img
                      src="/1.00/assets/files/avatars/avatar-1.png"
                      alt=""
                      className="img-fluid img-circle"
                    />
                  </span>
                  <span>{user.name}</span>
                </a>
                <ul
                  className="nav navbar-subnav collapse"
                  aria-hidden="true"
                  aria-labelledby="NavSettingsMenuButton"
                  id="navbar-nav-user"
                >
                  <li>
                    <a href="#">
                      <span>My profile</span>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <span>Settings</span>
                    </a>
                  </li>
                  <li className="no-dotted">
                    <Link to="/login">
                      <span>Log out</span>
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="has-submenu hide-on-xl">
                <a
                  href="#"
                  id="NavLanguageMenuButton"
                  aria-haspopup="true"
                  aria-expanded="false"
                  data-target="#navbar-nav-language"
                >
                  <span className="navbar-nav-icon">
                    <i
                      className="icon icon-d-block icon-globe"
                      aria-hidden="true"
                    ></i>
                  </span>
                  <span>Language</span>
                </a>
                <ul
                  className="nav navbar-subnav collapse"
                  aria-hidden="true"
                  aria-labelledby="NavLanguageMenuButton"
                  id="navbar-nav-language"
                >
                  <li>
                    <a href="#">Polish</a>
                  </li>
                  <li>
                    <a href="#" className="active">
                      English
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </nav>
        </div>
      </header>

      <main className="container-app container-app-logged-in" id="content">
        <div className="content-app">
          <Outlet context={[user, setUser]} />
        </div>
      </main>
    </div>
  );
}
