export default function ImportHistoryPage() {
  return (
    <div>
      <div className="content-header">
        <h1 className="h2 content-header-title">Import History</h1>
      </div>

      <div className="row"></div>
    </div>
  );
}
