import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";

export default function FileModal({ handleCancel, handleSave, show, title }) {
  const modalClass = show ? "modal fade show" : "hidden";
  const backdropClass = show ? "modal-backdrop show" : "hidden";
  const onDrop = useCallback(
    (acceptedFiles) => {
      handleSave(acceptedFiles[0]);
    },
    [handleSave]
  );
  const {
    isDragActive,
    getRootProps,
    getInputProps,
    isDragReject,
    open,
    acceptedFiles,
  } = useDropzone({
    onDrop,
    noClick: true,
    accept: "text/csv",
    maxFiles: 1,
    multiple: false,
  });

  return (
    <React.Fragment>
      <div className={backdropClass}></div>
      <div className={modalClass}>
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <button
                className="link modal-header-close"
                onClick={handleCancel}
              >
                <i className="icon icon-close" aria-hidden="true"></i>
                <span className="sr-only">Close modal</span>
              </button>
              <h2 className="modal-header-title">{title}</h2>
            </div>
            <div className="modal-body">
              <div className="file-uploader">
                <div className="file-uploader-dnd" {...getRootProps()}>
                  <span className="file-uploader-dnd-icon">
                    <i
                      className="icon icon-d-block icon-upload-2"
                      aria-hidden="true"
                    ></i>
                  </span>
                  <span className="file-uploader-dnd-label">
                    {!isDragActive && "Drag and drop file here"}
                    {isDragActive && !isDragReject && "Release file"}
                    <br />
                    or
                  </span>
                  <input {...getInputProps()} />
                  <button className="btn btn-secondary" onClick={open}>
                    Browse files
                  </button>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <ul className="nav modal-footer-nav">
                <li>
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => handleSave(acceptedFiles[0])}
                  >
                    Save
                  </button>
                </li>
                <li>
                  <button className="btn btn-tertiary" onClick={handleCancel}>
                    Cancel
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
