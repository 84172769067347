import React from "react";
import {DOTS, usePagination} from "./usePagination";

const Pagination = props => {
    const {
        onPageChange,
        siblingCount = 1,
        page,
        pages,
    } = props;

    const paginationRange = usePagination({
        pages,
        siblingCount,
        page
    });

    const onNext = () => {
        onPageChange(page + 1);
    };

    const onPrevious = () => {
        onPageChange(page - 1);
    };

    let lastPage = paginationRange[paginationRange.length - 1];
    return (
        <nav aria-label="Pagination" className="mt-5 mb-5">
            {page >= 1 && paginationRange.length >= 2 && (<ul className="nav pagination">
                <li>
                    <a className={(page === 1) ? "disabled" : ""} onClick={onPrevious}>
                        <span className="sr-only">Previous</span>
                        <i className="icon icon-chevron-small-left icon-d-block" aria-hidden="true"></i>
                    </a>
                </li>
                {paginationRange.map((pageNumber, idx) => {
                    if (pageNumber === DOTS) {
                        return <li key={idx}><span>&#8230;</span></li>;
                    }
                    return (
                        <li key={idx}>
                            <a
                                className={(pageNumber === page) ? "active" : ""}
                                onClick={() => onPageChange(pageNumber)}>
                                {pageNumber}
                            </a>
                        </li>
                    );
                })}
                <li>
                    <a className={(page === lastPage) ? "disabled" : ""} onClick={onNext}>
                        <span className="sr-only">Next</span>
                        <i className="icon icon-chevron-small-right icon-d-block" aria-hidden="true"></i>
                    </a>
                </li>
            </ul>)}
            {paginationRange.length < 2 && pages !== 0 && (
                <ul className="nav pagination"><li><a className="disabled">1</a></li></ul>
            )}
        </nav>
    );
};

export default Pagination;